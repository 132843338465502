import React, { useContext } from "react";
import _ from "lodash";
import * as styles from "./categoryTable.module.scss";
import { getToday } from "../../utils/utils";
import DomainContext from "../../context/DomainContext";
import { getCategoryPath } from "../../utils/urlMap";
import { Link } from "gatsby";

const today = getToday();

const HIDDEN_CATEGORIES = {
  ch: [83, 84, 85, 86, 91],
  en: [91],
};

const CategoryTable = ({ categoryList = [], name }) => {
  const domain = useContext(DomainContext);

  categoryList = hideCategories(categoryList, domain);

  return (
    <div className={styles.priceTableOutsideContainer}>
      <div className={styles.wContainer}>
        <h2 id="preise" className={styles.heading}>
          {`Tagesaktueller ${name} vom ${today}`}
        </h2>
        <span className={styles.subheading}>
          *Indikative Preise basierend auf Preisen in Deutschland für
          Teilladungen, angeliefert.
        </span>
        <div className={styles.scCategoriesTable}>
          {categoryList &&
            categoryList.map(item => (
              <div>
                <div>
                  <Link to={`/${getCategoryPath(item.slug)}`}>
                    <div className={styles.pb3}>
                      <div className={styles.nameContainer}>
                        <h3 className={styles.materialName}>{item.name}</h3>
                      </div>
                      <div className={styles.priceTdContainer}>
                        <strong className={styles.black}>
                          € {item["best_price"]}{" "}
                        </strong>{" "}
                        pro kg*
                      </div>
                      <div className={styles.preiseTdContainer}>
                        <span>Preise anzeigen</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
        </div>
        <br />
      </div>
      <div className={styles.priceTableBackgroundContainer}>
        <div className={styles.darkBackgroundDiagonal}></div>
        <div className={styles.darkBackground}></div>
      </div>
    </div>
  );
};

const hideCategories = (categoryList = [], domain) => {
  if (
    domain &&
    HIDDEN_CATEGORIES[domain] &&
    _.isArray(HIDDEN_CATEGORIES[domain])
  ) {
    categoryList = categoryList.filter(
      c => !HIDDEN_CATEGORIES[domain].includes(c.id)
    );
  }
  return categoryList;
};

export default CategoryTable;
