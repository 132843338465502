import React from "react";
import PreislandingLayout, {
  ProductListContext,
} from "../../layouts/PreislandingLayout";
import CategoryTable from "../../components/category-table/CategoryTable";
import PriceFAQ from "../../components/price-faq/PriceFAQ";
import SEOComponent from "../../components/Shared/Seo";

const SchrottpreisePage = () => {
  const { Consumer } = ProductListContext;
  return (
    <PreislandingLayout>
      <SEOComponent page="schrottpreise" />
      <Consumer>
        {value => (
          <CategoryTable
            categoryList={value.categoryList}
            name="Schrottpreise"
          />
        )}
      </Consumer>
      <PriceFAQ page="schrottpreise" />
    </PreislandingLayout>
  );
};

export default SchrottpreisePage;
